import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "the-map"
    }}>{`The Map`}</h1>
    <p><img alt="Map" src={require("../public/map-numbers.png")} /></p>
    <p>{`1 : `}<a parentName="p" {...{
        "href": "/players#spawn"
      }}>{`Spawns and regen pads`}</a><br parentName="p"></br>{`
`}{`2 : `}<a parentName="p" {...{
        "href": "/bots#guardians"
      }}>{`A Guardian`}</a><br parentName="p"></br>{`
`}{`3 : `}<a parentName="p" {...{
        "href": "/poles#bonus-poles"
      }}>{`Bonus Pole`}</a><br parentName="p"></br>{`
`}{`4 : `}<a parentName="p" {...{
        "href": "/poles#team-poles"
      }}>{`Team Poles`}</a><br parentName="p"></br>{`
`}{`5 : `}<a parentName="p" {...{
        "href": "/bots#gold-bots"
      }}>{`Gold bots`}</a>{` lives here`}<br parentName="p"></br>{`
`}{`6 : `}<a parentName="p" {...{
        "href": "/bots#team-bots"
      }}>{`Team bots`}</a>{` follow these paths  `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      